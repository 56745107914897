<template lang="pug">
transition(@leave='leave')
  loader(v-if="loaded === false")
h1
  .row
    span {{t['dqc_reports'] || 'dqc_reports'}} ({{reports?.length || 0}})
  .search-filters
    autocomplete.right(:data="autocompletedData(reports)" :options="{ placeholder: t.search }" :modelValue="autocompletedModel($root.filters)" @update:modelValue="autocompleteUpdateModel($root.$router, $root.filters, $event)")
.row
  subtitle(style="font-style: italic; margin-top: -10px;") {{ t['dqc_report_subtitle'] || 'dqc_report_subtitle'}}
.block.expand
  spreadsheet.stripped.expand(:data="filtered_reports" :options="{ columns:   ['Data Quality Report Name','Specialty', 'Data Report', 'Rules Number', 'Usage', 'Actions'], editable: false }")
    template(v-slot:cell-data-quality-report-name="{ column, line }")
      div {{ line.name }}
    template(v-slot:cell-specialty="{ column, line }")
      div {{ line?.dataReport?.specialtyName }}
    template(v-slot:cell-data-report="{ column, line }")
      div {{ line?.dataReport?.name }}
    template(v-slot:cell-rules-number="{ column, line }")
      div {{ line.rules?.length || 0 }}
    template(v-slot:cell-usage="{ column, line }")
      div {{ line.templates.length || 0 }}
    template(v-slot:cell-actions="{ column, line }")
      .row
        button.ghost(:tt="t.edit" @click="edit(line)")
          svg-icon(name="pt-icon-edit")
        button.ghost(:tt="t.duplicate" @click="duplicate(line)")
          svg-icon(name="ic_file_copy")
        button.ghost(:tt="t.export" @click="exportDataReport(line)")
          svg-icon(name="pt-icon-export")
        button.ghost(v-if="line.templates.length === 0" :tt="t.delete" @click="confirmRemoveId=line.id")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary.padding-button(@click="createDqcReport") {{t['create_dqc_report'] || 'create_dqc_report'}}
    button.primary.padding-button(@click="triggerFileInput") {{t['import_dqc_report'] || 'import_dqc_report'}}
      input.hidden(type="file" ref="fileInput" accept="application/json" @change="onImportReport")    
    button.primary.padding-button(@click="goToDqcRules") {{t['dqc_rules'] || 'dqc_rules'}}
    button.primary.padding-button(@click="goToDqcDashboard") {{t['dqc_dashboard'] || 'dqc_dashboard'}}
  popup(:show="confirmRemoveId")
    template(v-slot:header)
      | {{t["confirmation_remove"] || 'confirmation_remove'}}
    template(v-slot:content)
      | {{t["text_remove"] || 'text_remove'}}
    template(v-slot:action)
      button.secondary-action(@click="confirmRemoveId = null") {{t['confirmation_btn_close'] || 'confirmation_btn_close'}}
      button.main-action(@click="deleteDr(confirmRemoveId)") {{t["confirmation_btn_delete"] || 'confirmation_btn_delete'}}

  .div
    data-quality-report-import-popup(
      :show="showImportDqrPopup"
      :importData="uploadReport"
      :dataQualityReports="reports"
      :dataQualityRules="rules"
      @close="onCloseImportPopup"
    )
  .div.loader-overlay(v-if="loading")
    loading-icon
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useDataQualityReport } from '../composables/useDataQualityReport'
import { useDataQualityRule } from '../composables/useDataQualityRule'
import dataQualityReportService from '@100-m/hauru/src/services/DataQualityReportService'
import { readJSONFile } from '../../builder/lib/assetManagement'
import dataReportService from '@100-m/hauru/src/services/DataReportService'

export default {
  data() {
    return {
      loading: false,
      confirmRemoveId: null,
      searchFilters: [],
      showImportDqrPopup: false,
      uploadReport: null,
    }
  },
  setup() {
    const { reports, loaded, reload: reloadDataQualityReport } = useDataQualityReport()
    const { rules, reload: reloadRules } = useDataQualityRule()
    const autocompletedData = data => {
      const transformedData = data.map(item => {
        const { id, name, specialtyName, variables } = item.dataReport
        return {
          id,
          name,
          specialtyName,
          ...variables,
        }
      })
      return transformedData.reduce((acc, v) => {
        ;['shareId', 'specialtyName']
          .filter(k => k && typeof v[k] === 'string')
          .map(k => {
            acc[k] = acc[k] || {}
            acc[k][v[k]] = k === 'schedule' ? cron2def[v[k]] : v[k]
          })
        return acc
      }, {})
    }

    const autocompletedModel = filters => {
      return filters
        .filter((v, k) => k !== 'domain' && k !== 'year')
        .reduce((acc, v, k) => {
          if (v) {
            v.map(val => acc.push([k, val].join('.')))
          }
          return acc
        }, [])
    }

    const autocompleteUpdateModel = (router, filters, event) => {
      return router.push({
        query: Object.assign(
          $root.query.filter((v, k) => !filters.keys().toggle('domain').includes(k)),
          event
            .map(v => v.split('.'))
            .group('0')
            .map(g => g.map('1').join('|')),
        ),
      })
    }

    const filtered_reports = computed(() => {
      const authorized_filter = ['shareId', 'specialtyName']
      const filters = Object.entries($root.query)
        .filter(([k, v]) => !['domain', 'search', 'selected', 'id'].includes(k))
        .filter(d => authorized_filter.includes(d[0]))
        .map(([k, v]) => [k, v.split('|')])

      return reports.value
        .filter(d => !d.disabled)
        .filter(d =>
          filters.every(([k, vs]) =>
            vs.some(
              v =>
                d[k] === v ||
                (k === 'specialtyName' && d.dataReport?.specialtyName === v) ||
                (k === 'shareId' && d.dataReport?.variables?.shareId === v) ||
                (v.slice(0, 1) === '>' && d[k] > v.slice(1)) ||
                (v.slice(0, 1) === '<' && d[k] < v.slice(1)),
            ),
          ),
        )
    })

    onMounted(() => {})
    return {
      reports,
      rules,
      loaded,
      autocompletedData,
      autocompletedModel,
      autocompleteUpdateModel,
      filtered_reports,
      reloadDataQualityReport,
      reloadRules,
    }
  },
  methods: {
    async onCloseImportPopup(status, id) {
      switch (status) {
        case 'success': {
          // update the list
          this.reloadDataQualityReport()

          // or we can get the new report and add it to the list
          // dataQualityReportService.get(id).then(data => {
          //   const index = this.reports.findIndex(q => q.id === id)
          //   if (index === -1) {
          //     this.reports.push(data)
          //   } else {
          //     this.reports[index] = data
          //   }
          // })

          // update rules for next import
          this.reloadRules()
          this.loading = false
          break
        }
        case 'loading': {
          this.loading = true
          break
        }
        default: {
          this.loading = false
          break
        }
      }

      this.showImportDqrPopup = false
    },
    createDqcReport() {
      this.$router.push({ path: $root.appath + 'data-quality-report', query: { new: true } })
    },
    triggerFileInput() {
      this.$refs.fileInput.value = null
      this.uploadReport = null
      this.$refs.fileInput.click()
    },
    async parseUploadFile(file) {
      try {
        const data = await readJSONFile(file)

        if (data?.name) {
          return data
        }
        return null
      } catch {
        return null
      }
    },
    async onImportReport(event) {
      const data = await this.parseUploadFile(event)

      if (!data) {
        $root.toast({ description: $root.t.dqr_format_error, type: 'error', timeout: 5000 })
        return
      }

      this.uploadReport = data
      const drName = this.uploadReport.dataReport.name
      const res = await dataReportService.getByName(drName)

      if (!res) {
        $root.toast({ description: `${$root.t['dqc_error_name']}`, type: 'error', timeout: 5000 })
        return
      }

      this.showImportDqrPopup = true
    },
    goToDqcRules() {
      this.$router.push({ path: $root.appath + 'data-quality-rules', query: { new: true } })
    },
    goToDqcDashboard() {
      this.$router.push({ path: $root.appath + 'data-quality-dashboard' })
    },
    edit(line) {
      this.$router.push({ path: $root.appath + 'data-quality-report', query: { id: line.id } })
    },
    async exportDataReport(line) {
      try {
        this.loading = true
        const report = await dataQualityReportService.export(line.id)
        this.loading = false
        report['timestamp'] = new Date().getTime()
        JSON.stringify(report).download(`${$root.project}-${report.name}.json`)
        $root.toast({ description: $root.t['dqc_download_success'], type: 'success', timeout: 5000 })
      } catch (error) {
        $root.toast({ description: $root.t['dqc_download_failed'], type: 'error', timeout: 5000 })
      }
    },
    async duplicate(line) {
      const newName = prompt(
        'Enter report name',
        `${name.replace(/ \d\d\d\d-\d\d-\d\d \d\d:\d\d/, '')} ${new Date().format('YYYY-MM-DD hh:mm')}`,
      )
      if (newName && newName.length) {
        try {
          const createdReport = await dataQualityReportService.create(
            newName,
            line.rules.map(e => ({ ruleId: e.rule.id, tag: e.tag, path: e.path })),
            line.dataReport?.name,
          )
          this.$router.push({ path: $root.appath + 'data-quality-report', query: { id: createdReport.id } })
        } catch (error) {
          if (error.message) {
            let displayMessage = error.message
            if (error.message.includes('already exists')) {
              displayMessage = $root.t['dqc_report_already_exists']
            } else if (error.message.includes('must not be null')) {
              displayMessage = $root.t['error_null_value']
            }
            $root.toast({ description: displayMessage, type: 'error', timeout: 5000 })
          }
        }
      } else {
        $root.toast({ description: $root.t.missing_mandatory_fields, type: 'error', timeout: 5000 })
      }
    },
    async deleteDr(id) {
      try {
        await dataQualityReportService.deleteDataQualityReport(id)
      } catch (e) {
        $root.toast({
          description: $root.t['error_generic_report_dqc'] || 'error_generic_report_dqc',
          type: 'error',
          timeout: 5000,
        })
        return
      }
      this.reports.splice(
        this.reports.findIndex(q => q.id === id),
        1,
      )
      this.confirmRemoveId = null
      await this.init()
    },
  },
}
</script>

<style scoped>
.padding-button {
  margin-right: 8px;
}

h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}

h1 .search-filters .autocomplete {
  min-width: 90% !important;
}

.loader-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff3b;
}
</style>
