export function processGraph(graph, position, { share }) {
  if (
    !graph ||
    !graph.data ||
    graph.data.keys().length === 0 ||
    graph.data
      .v()
      .map(v => v.v().sum())
      .sum() === 0
  )
    return
  if (typeof graph.data === 'string')
    return {
      title: $root.t[graph.dimension] || graph.dimension,
      subtitle: 'CHECK INPUT DATA',
    }

  const filters = {
    default: d =>
      d
        .v()
        .map(v => Math.abs(v))
        .sum() !== 0,
    '.2%': d =>
      d
        .v()
        .map(v => Math.abs(v))
        .sum() >= 0.00005,
    '.2f': d =>
      d
        .v()
        .map(v => Math.abs(v))
        .sum() >= 0.005,
  }

  const formats = $root.config.formats || {}

  const { metric, dimension, group, rebased, data } = graph
  let type = 'hbar'
  let filter = filters[formats[metric]] || filters.default

  if (position === 0 || (position === 2 && $root.screen.path === 'factsheet_rendement')) type = 'pie'
  if (dimension === 'emetteur') type = 'table'
  if (dimension === 'currency_breakdown')
    filter = d => Math.abs(d.fund) > 0.001 || Math.abs(d.benchmark_relative) > 0.001
  const label = {
    weight_fund: 'pct_actif',
    weight_fund_filter: 'pct_actif_filter',
    exposure_fund: 'pct_expo',
    exposure_fund_filter: 'pct_expo_filter',
    nx_pack: 'pct_actif',
    nx_pack_contrib: 'contrib_sensi', // OK
    nx_pack_contrib_perf: 'contrib_perf', // OK
    nx_pack_contrib_volat_exsante: 'contrib_volat_exsante', // OK
    contrib_sensi_fund: 'contrib_sensi',
    contrib_sensi_fund_filter: 'contrib_sensi',
  }
  const overrideTitle = `${share.fund_url}_${dimension}`
  const title = $root.t[overrideTitle] || $root.t[dimension] || dimension
  const m = !rebased && !!group ? `${metric}_filter` : metric
  let concat_group = ''
  if (group) {
    const parts = group.split('_')
    if (parts.last() === 'only' && parts.length > 1) {
      concat_group = '_' + parts.at(-2) + '_' + parts.last()
    } else {
      concat_group = '_' + parts.last()
    }
  }
  const subtitle = (label[m] || 'no_subtitle').concat(concat_group)

  // TODO Other case to manage heres
  const direction =
    {
      contrib_perf_dirr: 'column',
      rating_comparaison: 'column',
      maturity_comparaison: 'column',
    }[dimension] || 'row'

  let resData = data
    .map(v => {
      if (!v.keys().includes('fund')) return v // Case YTD/MTD for contrip_perf
      if (!share.characteristics.display_benchmark_comp) return { fund: v.fund }
      return { fund: v.fund, benchmark_relative: v.benchmark_relative }
    })
    .filter(filter)
  if (dimension === 'emetteur')
    resData = resData.keys().map(el => ({
      issuer: el,
      weight: format('weight')(resData[el].fund),
    }))

  return {
    type,
    title,
    subtitle: $root.t[subtitle] || '',
    data: resData,
    options: {
      sort: metadata_sort(dimension, metric, position),
      format: {
        fund: formats[m] || '.2%',
        benchmark_relative: (formats[m] || '').replace('.2', '+.1') || '+.2%',
      },
      others: true,
      direction,
      limit: 11,
    },
  }
}

export function metadata_sort(dimension, metric, position) {
  let type = 'hbar'
  if (position === 0 || (position === 2 && $root.screen.path === 'factsheet_rendement')) type = 'pie'
  if (dimension === 'emetteur') {
    return ''
  }
  if (
    metric.includes('nx_pack') ||
    (type === 'pie' &&
      ![
        'lfam_sub_debt',
        'strat_mso_detailed_reporting',
        'tranche_maturite_monetaire',
        'rating_mmf',
        'tranche_maturite_with_call',
        'tranche_maturite',
        'taille_de_capi',
        'rating_comparaison',
        'rating_emetteur_non_signe',
        'rating_emission_non_signe',
        'rating_emission_a_defaut_emetteur',
        'rating_cmam_lt',
        'rating_cmam_ct',
      ].includes(dimension))
  ) {
    return ''
  } else {
    switch (dimension) {
      case 'strat_mso_detailed_reporting':
        return d =>
          [
            'strat_mso_detailed_reporting___mso_govies_core_euro',
            'strat_mso_detailed_reporting___mso_govies_peripheriques',
            'strat_mso_detailed_reporting___mso_govies_etats_unis',
            'strat_mso_detailed_reporting___mso_govies_autres_pays_developpes',
            'strat_mso_detailed_reporting___mso_credit_ig',
            'strat_mso_detailed_reporting___mso_credit_hy',
            'strat_mso_detailed_reporting___mso_credit_sub',
            'strat_mso_detailed_dirr___classe_d_actif___monetaire___obligations_ct',
            'strat_mso_detailed_reporting___mso_diversification_dettes_emergentes',
            'strat_mso_detailed_reporting___mso_diversification_convertibles',
          ].indexOf(d.key)
      case 'tranche_maturite_monetaire':
        return d =>
          [
            'tranche_maturite_monetaire_obligations__inf_1_mois_',
            'tranche_maturite_monetaire_obligations__sup_1_mois_',
            'tranche_maturite_monetaire_obligations__sup_3_mois_',
            'tranche_maturite_monetaire_obligations__sup_6_mois_',
            'tranche_maturite_monetaire_obligations__sup_9_mois_',
            'tranche_maturite_monetaire_obligations__1_an___2_ans_',
          ].indexOf(d.key)
      case 'rating_mmf':
        return d =>
          [
            'rating_mmf_a1_plus',
            'rating_mmf_a1',
            'rating_mmf_a2',
            'rating_mmf_a3',
            'rating_mmf_b',
            'rating_mmf_c',
            'rating_mmf_unknown',
          ].indexOf(d.key)
      case 'tranche_maturite_with_call':
        return d =>
          [
            'tranche_maturite_with_call__moins_1_an',
            'tranche_maturite_with_call__1_a_3_ans',
            'tranche_maturite_with_call__3_a_5_ans',
            'tranche_maturite_with_call__5_a_7_ans',
            'tranche_maturite_with_call__7_a_10_ans',
            'tranche_maturite_with_call__10_a_15_ans',
            'tranche_maturite_with_call__sup_a_15_ans',
          ].indexOf(d.key)
      case 'tranche_maturite':
        return d =>
          [
            'tranche_maturite__moins_1_an',
            'tranche_maturite__1_a_3_ans',
            'tranche_maturite__3_a_5_ans',
            'tranche_maturite__5_a_7_ans',
            'tranche_maturite__7_a_10_ans',
            'tranche_maturite__10_a_15_ans',
            'tranche_maturite__sup_a_15_ans',
          ].indexOf(d.key)
      case 'taille_de_capi':
        return d =>
          [
            'taille_de_capi_small_cap',
            'taille_de_capi_mid_cap',
            'taille_de_capi_large_cap',
            'taille_de_capi_dirr__titres_non_cotes',
            'NA',
          ].indexOf(d.key)
      case 'taille_de_capi_small':
        return d =>
          [
            'taille_de_capi_small_micro_cap_inf250m',
            'taille_de_capi_small_small_cap_sup250m_inf500m',
            'taille_de_capi_small_small_cap_sup500m_inf1mds',
            'taille_de_capi_small_mid_cap',
            'taille_de_capi_small_large_cap',
            'NA',
          ].indexOf(d.key)
      case 'rating_comparaison':
        return d => ['AAA', 'AA', 'A', 'BBB', 'BB', 'B', 'CCC', 'CC', 'C', 'DDD', 'DD', 'D', 'NA', 'NR'].indexOf(d.key)
      case 'rating_cmam_lt':
        return d => ['AAA', 'AA', 'A', 'BBB', 'BB', 'B', 'CCC', 'CC', 'C', 'R', 'D', 'NR', 'NIG'].indexOf(d.key)
      case 'rating_cmam_ct':
        return d => ['A-1+/A-1', 'A-2', 'A-3', 'B', 'C', 'D', 'NR'].indexOf(d.key)
      case 'rating_emetteur_non_signe':
        return d =>
          [
            'rating_emetteur_non_signe_dirr___rating_emetteur_aaa',
            'rating_emetteur_non_signe_dirr___rating_emetteur_aa',
            'rating_emetteur_non_signe_dirr___rating_emetteur_a',
            'rating_emetteur_non_signe_dirr___rating_emetteur_bbb',
            'rating_emetteur_non_signe_dirr___rating_emetteur_bb',
            'rating_emetteur_non_signe_dirr___rating_emetteur_b',
            'rating_emetteur_non_signe_dirr___rating_emetteur_ccc',
            'rating_emetteur_non_signe_dirr___rating_emetteur_cc',
            'rating_emetteur_non_signe_dirr___rating_emetteur_c',
            'rating_emetteur_non_signe_dirr___rating_emetteur_d',
            'NA',
            'rating_emetteur_non_signe_dirr___rating_emetteur_nr',
          ].indexOf(d.key)
      case 'rating_emission_non_signe':
        return d =>
          [
            'rating_emission_non_signe_dirr___rating_emission_aaa',
            'rating_emission_non_signe_dirr___rating_emission_aa',
            'rating_emission_non_signe_dirr___rating_emission_a',
            'rating_emission_non_signe_dirr___rating_emission_bbb',
            'rating_emission_non_signe_dirr___rating_emission_bb',
            'rating_emission_non_signe_dirr___rating_emission_b',
            'rating_emission_non_signe_dirr___rating_emission_ccc',
            'rating_emission_non_signe_dirr___rating_emission_cc',
            'rating_emission_non_signe_dirr___rating_emission_c',
            'rating_emission_non_signe_dirr___rating_emission_d',
            'rating_emission_non_signe_dirr___rating_emission_nr',
          ].indexOf(d.key)
      case 'rating_emission_a_defaut_emetteur':
        return d =>
          [
            'rating_emission_a_defaut_emetteur_aaa_plus',
            'rating_emission_a_defaut_emetteur_aaa',
            'rating_emission_a_defaut_emetteur_aaa_moins',
            'rating_emission_a_defaut_emetteur_aa_plus',
            'rating_emission_a_defaut_emetteur_aa',
            'rating_emission_a_defaut_emetteur_aa_moins',
            'rating_emission_a_defaut_emetteur_a_plus',
            'rating_emission_a_defaut_emetteur_a',
            'rating_emission_a_defaut_emetteur_a_moins',
            'rating_emission_a_defaut_emetteur_bbb_plus',
            'rating_emission_a_defaut_emetteur_bbb',
            'rating_emission_a_defaut_emetteur_bbb_moins',
            'rating_emission_a_defaut_emetteur_bb_plus',
            'rating_emission_a_defaut_emetteur_bb',
            'rating_emission_a_defaut_emetteur_bb_moins',
            'rating_emission_a_defaut_emetteur_b_plus',
            'rating_emission_a_defaut_emetteur_b',
            'rating_emission_a_defaut_emetteur_b_moins',
            'rating_emission_a_defaut_emetteur_ccc_plus',
            'rating_emission_a_defaut_emetteur_ccc',
            'rating_emission_a_defaut_emetteur_ccc_moins',
            'rating_emission_a_defaut_emetteur_cc_plus',
            'rating_emission_a_defaut_emetteur_cc',
            'rating_emission_a_defaut_emetteur_cc_moins',
            'rating_emission_a_defaut_emetteur_c_plus',
            'rating_emission_a_defaut_emetteur_c',
            'rating_emission_a_defaut_emetteur_c_moins',
            'rating_emission_a_defaut_emetteur_ddd_plus',
            'rating_emission_a_defaut_emetteur_ddd',
            'rating_emission_a_defaut_emetteur_ddd_moins',
            'rating_emission_a_defaut_emetteur_dd_plus',
            'rating_emission_a_defaut_emetteur_dd',
            'rating_emission_a_defaut_emetteur_dd_moins',
            'rating_emission_a_defaut_emetteur_d_plus',
            'rating_emission_a_defaut_emetteur_d',
            'rating_emission_a_defaut_emetteur_d_moins',
            'rating_emission_a_defaut_emetteur_unknown',
            'NA',
          ].indexOf(d.key)
      case 'lfam_sub_debt':
        return d =>
          [
            'lfam_sub_debt_coco_s',
            'lfam_sub_debt_hybrid_corporate',
            'lfam_sub_debt_assurance',
            'lfam_sub_debt_tier_1',
            'lfam_sub_debt_tier_2',
            'lfam_sub_debt_dirr___non_subordonnees',
            'NA',
          ].indexOf(d.key)
      case 'rating_assurance_emission_non_signe':
        return d =>
          [
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_aaa',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_aa',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_a',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_bbb',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_bb',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_b',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_ccc',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_cc',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_c',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_d',
            'rating_assurance_emission_non_signe_dirr___rating_assurance_emission_nr',
            'NA',
          ].indexOf(d.key)
      case 'rating_assurance_emetteur_non_signe':
        return d =>
          [
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_aaa',
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_aa',
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_a',
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_bbb',
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_bb',
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_b',
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_ccc',
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_cc',
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_c',
            'rating_assurance_emetteur_non_signe_dirr___rating_assurance_emetteur_nr',
            'NA',
          ].indexOf(d.key)
      default:
        return d => -d.value.fund
    }
  }
}
