import { GraphQlService } from './GraphQlService'
import { restService } from './RestService'

export class DataQualityReportService extends GraphQlService {
  async all() {
    return (
      await this.query(
        `
        query getAll {
          dataQualityCheckReports {
            getDQCReports {
              id
              name
              rules {
                rule {
                  name
                  id
                }
                path
                tag
              }
              dataReport {
                id
                name
                variables
                specialtyName
              }
              templates {
                id
              }
            }
          }
        }`,
      )
    ).data.dataQualityCheckReports.getDQCReports
  }

  async get(id) {
    return (
      await this.query(
        `
        query get($id: Int!) {
          dataQualityCheckReports {
            getDQCReport(id: $id) {
              id
              name
              rules {
                rule {
                  name
                  id
                }
                path
                tag
              }
              dataReport {
                id
                name
                variables
              }
               templates {
                id
              }
            }
          }
        }
        `,
        {
          id: +id,
        },
      )
    ).data.dataQualityCheckReports.getDQCReport
  }

  async export(id) {
    return (
      await this.query(
        `
        query export($id: Int!) {
          dataQualityCheckReports {
              getDQCReport(id: $id) {
                    name
                    rules {
                        rule {
                            name
                            dqcJavaScriptFunction
                        }
                        path
                        tag
                    }
                    dataReport {
                        name
                        #variables
                    }
                }
            }
        }`,
        {
          id: +id,
        },
      )
    ).data.dataQualityCheckReports.getDQCReport
  }

  async create(name, rules, dataReport) {
    return (
      await this.query(
        `
        mutation CreateDQCReport($name: String!, $rules: [Rule!]!, $dataReport: String!) {
          createDQCReport(name: $name, rules: $rules, dataReport: $dataReport) {
            id
          }
        }
        `,
        {
          name,
          rules,
          dataReport,
        },
      )
    ).data.createDQCReport
  }

  async update(id, name, rules, dataReport) {
    return (
      await this.query(
        `
        mutation Mutation($id: Int!, $name: String, $rules: [Rule!], $dataReport: String) {
          updateDQCReport(id: $id, name: $name, rules: $rules, dataReport: $dataReport) {
            id
          }
        }
        `,
        {
          id: +id,
          name,
          rules,
          dataReport,
        },
      )
    ).data.updateDQCReport
  }

  async deleteDataQualityReport(id) {
    return (
      await this.query(
        `
        mutation delete($deleteDqcReportId: Int!) {
          deleteDQCReport(id: $deleteDqcReportId)
        }
        `,
        {
          deleteDqcReportId: id,
        },
      )
    ).data.deleteDQCReport
  }

  async getResultsByPressIdsAndDomains(pressIds, domains) {
    return (
      await this.query(
        `
        query DataQualityCheckResults($pressIds: [Int!]!, $domains: [String!]!) {
          dataQualityCheckResults {
            getAllLastDQCResultsByPressIdsAndDomains(pressIds: $pressIds, domains: $domains) {
              id
              results
              status
              variables
              domain
              pressId
              createdAt
            }
          }
        }
        `,
        {
          pressIds,
          domains,
        },
      )
    ).data.dataQualityCheckResults.getAllLastDQCResultsByPressIdsAndDomains
  }

  async run(id, variables, options = { preProcess: false, postProcess: false }) {
    const toQuery = obj =>
      Object.entries(obj || {}).reduce((a, [k, v]) => `${a}${a ? '&' : '?'}${k}=${encodeURI(v)}`, '')

    try {
      const response = await fetch(
        `${config.graphqlEndpoint}/dqc-dr/${id}/run${toQuery({
          ...variables,
          token: $root?.profile?.idToken,
          preProcess: options.preProcess,
          postProcess: options.postProcess,
        })}`,
      )
      if (!response.ok) {
        const errors = await response.json()
        throw new Error(`Request failed with status ${response.status}\n${errors.map(e => e.message).join('\n')}`)
      }

      const data = await response.json()
      return { data, error: null }
    } catch (error) {
      return { data: null, error: error.message || 'Unexpected error' }
    }
  }

  async import(payload) {
    try {
      const data = await restService.post('dqc-dr/import', payload)
      return data
    } catch (error) {
      return { data: null, error: error.message || 'Unexpected error' }
    }
  }
}

const dataQualityReportService = new DataQualityReportService()
export default dataQualityReportService
