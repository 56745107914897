import { onMounted, ref } from 'vue'
import dataQualityRuleService from '../../../services/DataQualityRuleService'

export function useDataQualityRule() {
  const rules = ref([])
  const loaded = ref(false)

  const reload = async () => {
    const data = await dataQualityRuleService.all()
    rules.value = data
  }

  onMounted(async () => {
    await reload()
    loaded.value = true
  })

  return {
    rules,
    loaded,
    reload,
  }
}
