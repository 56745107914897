<template lang="pug" v-if="loaded">
.row
  h1 {{t.dqc_rules || 'dqc_rules' }} ({{rules?.length || 0}})
.row
  subtitle(style="font-style: italic; margin-top: -10px;") {{ t['dqc_rules_subtitle'] || 'dqc_rules_subtitle'}}
.block.expand
  spreadsheet.stripped.expand.nosort(:data="rules" :options="{ columns: ['Rules Name', 'Usage in DQC Reports', 'Actions'], editable: false }")
    template(v-slot:cell-rules-name="{ column, line }")
      div {{ line.name }}
    template(v-slot:cell-usage-in-dqc-reports="{ column, line }")
      div {{ line.dataQualityCheckReports?.length || 0}}
    template(v-slot:cell-actions="{ column, line }")
      .row
        button.ghost(:tt="t.edit" @click="edit(line)")
          svg-icon(name="pt-icon-edit")
        button.ghost(:tt="t.duplicate" @click="duplicate(line)")
          svg-icon(name="ic_file_copy")
        button.ghost(:tt="t.export" @click="exportDataReportRule(line)")
          svg-icon(name="pt-icon-export")
        button.ghost(v-if="line.dataQualityCheckReports?.length === 0" :tt="t.delete" @click="confirmRemoveId=line.id")
          svg-icon(name="pt-icon-trash")
  .row(style="display: flex; gap: 8px;")
    button.primary(@click="createRule") {{t['dqc_create_rule'] || 'dqc_create_rule'}}
    button.primary(@click="triggerFileInput") {{t['dqr_import_rule'] || 'dqr_import_rule'}}
      input.hidden(type="file" ref="fileInput" accept="application/json" @change="onImportRule")
  popup(:show="confirmRemoveId")
    template(v-slot:header)
      | {{t["confirmation_remove"]}}
    template(v-slot:content)
      | {{t["text_remove"]}}
    template(v-slot:action)
      button.secondary-action(@click="confirmRemoveId = null") {{t['confirmation_btn_close']}}
      button.main-action(@click="deleteDr(confirmRemoveId)") {{t["confirmation_btn_delete"]}}
  popup(v-if="isShowImportConflictPopup" :show="isShowImportConflictPopup")
    template(v-slot:header)
      | {{t["confirmation_remove"]}}
    template(v-slot:content)
      div {{ t["dqr_already_exists"] + `:`}}
      div(style="padding-bottom: 8px") {{ this.uploadedRule?.ruleName}}
    template(v-slot:action)
      .flex.gap-2
      button(
        v-for="action in this.getImportConflictActions()"
        :key="action.value"
        :class="action.value === this.importTypes.CANCEL ? 'secondary-action' : 'main-action'"
        @click="() => action.onClick({importType: action.value})"
      ) {{ action.label }}
</template>

<script>
import { onMounted } from 'vue'
import { useDataQualityRule } from '../composables/useDataQualityRule'
import dataQualityRuleService from '../../../services/DataQualityRuleService'
import { readJSONFile } from '../../builder/lib/assetManagement'
import { generateUniqueName } from '../utils/generateUniqueName'

const IMPORT_CONFLITS_ACTIONS = {
  OVERRITE: 'OVERRITE',
  CREATE_COPY: 'CREATE_COPY',
  CANCEL: 'CANCEL',
}

export default {
  data() {
    return {
      confirmRemoveId: null,
      isShowImportConflictPopup: false,
      importTypes: IMPORT_CONFLITS_ACTIONS,
      uploadedRule: null,
    }
  },
  setup() {
    const { rules, loaded } = useDataQualityRule()
    onMounted(() => {})
    return {
      rules,
      loaded,
    }
  },

  methods: {
    getImportConflictActions() {
      return [
        { label: $root.t['cancel'], value: IMPORT_CONFLITS_ACTIONS.CANCEL },
        { label: $root.t['overwrite'], value: IMPORT_CONFLITS_ACTIONS.OVERRITE },
        { label: $root.t['create_copy'], value: IMPORT_CONFLITS_ACTIONS.CREATE_COPY },
      ].map(action => {
        return {
          ...action,
          onClick: this.onImportActionClick,
        }
      })
    },
    async onImportActionClick({ importType }) {
      try {
        switch (importType) {
          case IMPORT_CONFLITS_ACTIONS.OVERRITE:
            await this.importRuleByUpdate()
            break
          case IMPORT_CONFLITS_ACTIONS.CREATE_COPY:
            await this.importRuleByCreateCopy()
            break
          default:
            break
        }
      } catch (e) {
        e && $root.toast({ description: e.message, type: 'error', timeout: 5000 })
      } finally {
        this.resetAfterImport()
      }
    },
    resetAfterImport() {
      this.isShowImportConflictPopup = false
      this.uploadedRule = null
      this.$refs.fileInput.value = null
    },
    createRule() {
      this.$router.push({ path: $root.appath + 'data-quality-rule', query: { new: true } })
    },
    isValidRule(rule) {
      return rule && rule.name && rule.dqcJavaScriptFunction
    },
    findRuleWithSameName() {
      const ruleWithSameName = this.rules.find(rule => rule.name === this.uploadedRule.ruleName)
      return ruleWithSameName
    },
    async importRuleByCreateCopy() {
      const { ruleName } = this.uploadedRule
      this.uploadedRule.ruleName = generateUniqueName(
        ruleName + ' (copy)',
        this.rules.map(rule => rule.name),
      )
      this.uploadedRule.id = null
      this.importRuleByCreate()
    },
    async importRuleByUpdate() {
      const { id, ruleName, ruleFunction } = this.uploadedRule
      await dataQualityRuleService.update(id, ruleName, ruleFunction)
      $root.toast({ description: `${$root.t['dqr_import_success']}: ${ruleName}`, type: 'success', timeout: 5000 })
    },
    async importRuleByCreate() {
      const { ruleName, ruleFunction } = this.uploadedRule
      const res = await dataQualityRuleService.createDataQualityRule(ruleName, ruleFunction)
      $root.toast({ description: `${$root.t['dqr_import_success']}: ${ruleName}`, type: 'success', timeout: 5000 })
      this.rules.push({
        id: res.id,
        name: ruleName,
        dqcJavaScriptFunction: ruleFunction,
        dataQualityCheckReports: [],
      })
    },
    // should export
    async importRule(rule) {
      const ruleWithSameName = this.findRuleWithSameName(this.uploadedRule)

      if (ruleWithSameName) {
        this.isShowImportConflictPopup = true
        this.uploadedRule.id = ruleWithSameName.id
      } else {
        await this.importRuleByCreate()
        this.resetAfterImport()
      }
    },
    async onImportRule(event) {
      const uploaded = await readJSONFile(event)

      if (!uploaded || !this.isValidRule(uploaded)) {
        $root.toast({ description: $root.t.dqr_format_error, type: 'error', timeout: 5000 })
        return
      }

      this.uploadedRule = {
        ruleName: uploaded.name,
        ruleFunction: uploaded.dqcJavaScriptFunction,
        // timestamp: uploaded.timestamp,
        id: null,
      }

      this.importRule()
    },
    triggerFileInput() {
      this.uploadedRule = null
      this.$refs.fileInput.click()
    },
    edit(line) {
      this.$router.push({ path: $root.appath + 'data-quality-rule', query: { id: line.id } })
    },
    async exportDataReportRule(line) {
      const rule = await dataQualityRuleService.get(line.id)
      rule['timestamp'] = new Date().getTime()
      JSON.stringify(rule).download(`${$root.project}-${rule.name}.json`)
      $root.toast({ description: $root.t.dqr_download_success, type: 'success', timeout: 5000 })
    },
    async duplicate(line) {
      const rule = await dataQualityRuleService.get(line.id)
      const newName = prompt(
        'Enter rule name',
        `${name.replace(/ \d\d\d\d-\d\d-\d\d \d\d:\d\d/, '')} ${new Date().format('YYYY-MM-DD hh:mm')}`,
      )
      if (newName && newName.length) {
        try {
          const createdRule = await dataQualityRuleService.createDataQualityRule(newName, rule.dqcJavaScriptFunction)
          this.$router.push({ path: $root.appath + 'data-quality-rule', query: { id: createdRule.id } })
        } catch (error) {
          if (error.message) {
            let displayMessage = error.message
            if (error.message.includes('already exists')) {
              displayMessage = $root.t.rule_already_exists
            } else if (error.message.includes('must not be null')) {
              displayMessage = $root.t['error_null_value']
            }
            $root.toast({ description: displayMessage, type: 'error', timeout: 5000 })
          }
        }
      } else {
        $root.toast({ description: $root.t.missing_mandatory_fields, type: 'error', timeout: 5000 })
      }
    },
    async deleteDr(id) {
      try {
        await dataQualityRuleService.deleteDataQualityRule(id)
      } catch (e) {
        this.confirmRemoveId = null
        $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        return
      }
      this.rules.splice(
        this.rules.findIndex(q => q.id === id),
        1,
      )
      this.confirmRemoveId = null
      await this.init()
    },
  },
}
</script>

<style scoped></style>
