import { onMounted, ref } from 'vue'
import dataQualityReportService from '../../../services/DataQualityReportService'

export function useDataQualityReport() {
  const reports = ref([])
  const loaded = ref(false)

  const reload = async () => {
    const reportList = await dataQualityReportService.all()
    reports.value = reportList
  }

  onMounted(async () => {
    await reload()
    loaded.value = true
  })

  return {
    reports,
    loaded,
    reload,
  }
}
