<style>
.lang-nl_be .block h2 {
  margin-bottom: 4px;
  margin-top: 4px;
}
.lang-fr_be .block h2 {
  margin-bottom: 4px;
  margin-top: 4px;
}
</style>

<template lang="pug">
.pdf-page.grid.page1(:class="{[data.share.characteristics.theme]: true, en_hk: $root.lang === 'en_hk'}")
    pdf-header(:share="data.share" :period="period" :domain="perf_domain")
    .row.expand
      .column
        brick.list(title="key_figures" v-if="!data.share.characteristics.mandat_fictif")
          pdf-table(:data="key_figures" :metadata="{ hide: true }")
        brick(title="risk_scale" v-if="Number(data.share.characteristics.risk_scale) && !data.share.characteristics.mandat_fictif")
          pdf-risk-scale(:risk-scale="Number(data.share.characteristics.risk_scale)")
        brick(title="investment_period" v-if="data.share.characteristics.horizon")
          pdf-investment-period(:horizon="data.share.characteristics.horizon")
        brick.list(title="characteristics" :data="data.tables.characteristics")
        brick.list(v-if="data.share.characteristics.managers_picture" title="commercial_informations"
          :data="data.tables.customer_informations.filter(subArray => subArray[0] !== 'managers')")
        brick.list(v-else title="commercial_informations" :data="data.tables.customer_informations")
        brick(v-if="data.share.characteristics.ms_overall_rating")
          morningstar(:starNumber="data.share.characteristics.ms_overall_rating? +data.share.characteristics.ms_overall_rating : null")
        brick(v-if="data.share.characteristics.manager_names_for_pictures")
          manager_image(:managersData="data.share.characteristics.manager_names_for_pictures")
        brick.list(title="esg_characteristic" :data="data.tables.esg_table" v-if="data.share.characteristics.esg_table")
        brick.risk_disclaimer.narrow.bottom(v-if="data.share.characteristics.disclaimer_page_bottom")
          div(v-html="t[data.share.characteristics.disclaimer_page_bottom] || data.share.characteristics.disclaimer_page_bottom")
      pdf-first-page-no-perf(v-if="!has_enough_perf_data" :data="data")
      .column(v-if="has_enough_perf_data")
        brick.risk_disclaimer.narrow(v-if="data.share.characteristics.disclaimer_top")
          pdf-text(:data="t[data.share.characteristics.disclaimer_top] || data.share.characteristics.disclaimer_top")
        brick(title="investment_strategy")
          pdf-text(:data="t[data.share.characteristics.orientation] || data.share.characteristics.orientation")
        //perf cumul & annualized
        // condition below was wrong in prod: https://github.com/100-m/lafrancaise/blob/c5ffd603e22c62b02ac0c4a5642e81ee6938b261/src/applications/asset-management/screens/_userflow/factsheet.vue#L187
        // v-if="data.share.characteristics.performance_historical !== 'performance_historical_barchart'"
        brick(
          :title="performance_type_title(data, perf_domain)"
          :subtitle="$root.lang.includes('be') && (t['performance_historical_subtitle'] || 'performance_historical_subtitle')"
        )
          pdf-text(:data="t[data.share.characteristics.disclaimer_perf] || t.disclaimer_perf")
        brick.center-value.uniform.align-value(v-if="!$root.lang.includes('be')")
          pdf-table(:data="cumulated_net")
        brick.center-value.uniform.align-value-be(v-if="$root.lang.includes('be')")
          pdf-table(:data="annualized_net")
          div.source {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        brick.center-value.uniform.align-value(v-else)
          pdf-table(:data="annualized_net")
        //monthly perf if performance_historical !== 'performance_historical_barchart' && not BE
        brick.center-value(
          :title="(data.share.characteristics.mandat_is_perf_net || data.share.characteristics.mandat_is_perf_net === '')  ? 'performance_historical' : 'performance_brute_historical'"
          v-if="data.share.characteristics.performance_historical !== 'performance_historical_barchart' && !$root.lang.includes('be')"
        )
          pdf-table(:data="historical_table")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        //yearly barchart if performance_historical === 'performance_historical_barchart || lang = fr_be
        brick(
          :title="$root.lang.includes('be') ? ($root.t['performance_historical_yearly'] + ' ' + new Intl.DateTimeFormat($root.lang.slice(0,2)).format(new Date(perf_domain[1]))) : 'performance_historical_yearly'"
          :subtitle="$root.lang.includes('be') && (t['performance_historical_subtitle'] || 'performance_historical_subtitle')"
          type="vbar"
          :data="yearly_asof.data"
          :options="yearly_asof.options"
          v-if="data.share.characteristics.performance_historical === 'performance_historical_barchart' || $root.lang.includes('be')"
        )
          pdf-text(:data="t[data.share.characteristics.disclaimer_perf] || t.disclaimer_perf")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        //historical_asof (linechart)
        brick(
          :title="$root.lang.includes('be') ? ($root.t[historical_asof_filtered.title] + ' ' + new Intl.DateTimeFormat($root.lang.slice(0,2)).format(new Date(perf_domain[1]))) : historical_asof_filtered.title"
          type="line"
          :data="historical_asof_filtered.data"
          :options="historical_asof_filtered.options"
          @rebind="historical_asof_filtered.options.format_xAxis && $event.xAxis.axisConfigurations([[parseAxis(historical_asof_filtered.options.format_xAxis )]])"
        )
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        //perf indicators
        brick.nomargin.expand(title="performance_indicators")
          .row
            brick.no-bold.center-value.performance_indicators.expand(:data="data.tables.performance_table_left")
            brick.no-bold.center-value.performance_indicators.expand(:data="data.tables.performance_table_right" v-if="data.share.characteristics.performance_table_right")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    pdf-footer(:share="data.share.characteristics")
</template>

<script>
import page1mix from '../mixins/page1mix'
import pdf from '../mixins/pdf'
export default {
  mixins: [pdf, page1mix],
  // data() {
  //   return {
  //     period: this.$route.query.period || 'monthly',
  //   }
  // },
  props: {
    data: {
      type: Object,
      required: true,
    },
    period: {
      type: String,
      required: true,
    },
  },
  methods: {
    performance_type_title(data, perf_domain) {
      if (data.share.characteristics.mandat_is_perf_net || data.share.characteristics.mandat_is_perf_net === '') {
        if ($root.lang.includes('be')) {
          return (
            $root.t['performance_net'] +
            ' ' +
            new Intl.DateTimeFormat($root.lang.slice(0, 2)).format(new Date(perf_domain[perf_domain.length - 1]))
          ) //! check this !!!!! perf_domain[1]
        }
        return $root.t['performance_net']
      } else {
        if ($root.lang.includes('be')) {
          return (
            $root.t['performance_brut'] +
            ' ' +
            new Intl.DateTimeFormat($root.lang.slice(0, 2)).format(new Date(perf_domain[perf_domain.length - 1]))
          ) //! check this !!!!!
        }
        return $root.t['performance_brut']
      }
    },
  },
  computed: {
    has_enough_perf_data() {
      if (!this.data.share.characteristics.inception_date) return true
      const insception = new Date(this.data.share.characteristics.inception_date.split('/').reverse().join('-'))
      return new Date(this.perf_domain[1]).minus('year') >= new Date(insception)
    },
  },
}
</script>
