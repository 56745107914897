<template>
  <button class="print-button flex items-center gap-2 rounded border bg-transparent px-3 py-2" @click="onPrint">
    <ui-asset :width="20" :height="20" name="icon_printer"></ui-asset>
    <div class="font-semibold 2xl:block">{{ $root.t['print'] || 'Print' }}</div>
  </button>
</template>
<script setup lang="ts">
const onPrint = () => {
  window.print()
}
</script>
<style scoped>
.print-button {
  cursor: pointer;
  border-color: var(--primary);
  transition: background-color 0.3s ease;
}

.print-button:hover {
  background-color: color-mix(in srgb, var(--primary) 20%, transparent);
}
</style>
