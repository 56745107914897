<template>
  <popup :show="show" v-if="importConfigRef">
    <template #header>
      {{ $root.t['import_dqc_report'] }}
    </template>

    <template #content>
      <check-duplication-component
        class="mb-3"
        :title="$root.t['dqc_report_name']"
        :value="importConfigRef?.name"
        :onChangeValidation="(name: string) => onChangeValidation({ type: 'dataQualityReport', name })"
      />

      <div class="flex-column flex gap-1 pb-2">
        <div v-for="(rule, index) in importConfigRef.rules || []" :key="index">
          <check-duplication-component
            :title="$root.t['rule'] + ' ' + (index + 1)"
            :value="rule.rule.name"
            :onChangeValidation="(name: string) => onChangeValidation({ type: 'rules', name, index })"
          />
        </div>
      </div>
    </template>

    <template #action>
      <button class="secondary-action" @click="closePopup">Cancel</button>
      <div v-if="isNoDuplication">
        <button class="main-action" @click="() => onActionTrigger(ImportType.DEFAULT)">
          {{ $root.t['import'] }}
        </button>
      </div>
      <div v-else>
        <button class="main-action" @click="() => onActionTrigger(ImportType.CREATE_COPY)">
          {{ $root.t['create_copy'] }}
        </button>
        <button class="main-action" @click="() => onActionTrigger(ImportType.OVERWRITE)">
          {{ $root.t['overwrite'] }}
        </button>
      </div>
    </template>
  </popup>
</template>

<script setup lang="ts">
import dataQualityReportService from '@100-m/hauru/src/services/DataQualityReportService'
import { ref, computed, watch } from 'vue'
import { generateUniqueName } from '../../utils/generateUniqueName'

// #region typing
// Define an enum for import types
enum ImportType {
  OVERWRITE = 'overwrite',
  CREATE_COPY = 'create_copy',
  DEFAULT = 'import',
}

export type ValidationResponse = {
  // validation rules result
  isValid: boolean
  // error message if any
  errMsg: string
  // existing Id if a duplication found
  existId?: string | number | null
}

// an item that we wish to config(like validation) before import
type ConfigurableItem<T> = T &
  // importType is been controlled from the group level
  // as we don't give the option for user to choose from each item level, which is verbose
  // Partial<{ importType: ImportType; }> &

  // each item should been validated before import
  Partial<ValidationResponse>

// base item type
type ItemConfig = ConfigurableItem<{
  name: string
}>

type Rule = ItemConfig

type RuleWithPath = {
  rule: Rule
  path: string
  tag: string
}

// data container for the import api
type ImportData = ItemConfig &
  Partial<{
    rules: RuleWithPath[]
    dataReport: {
      name: string
    }
  }>
// #endregion

const props = defineProps<{
  show: boolean
  importData: ImportData
  dataQualityReports: any[]
  dataQualityRules: any[]
}>()

const emit = defineEmits(['close'])

const importConfigRef = ref<ImportData>()
const importTypeRef = ref<ImportType>()

// load props to config
watch(
  () => props.importData,
  newImportData => {
    if (!newImportData) return
    importConfigRef.value = {
      ...newImportData,
      existId: null,
    }
  },
  { immediate: true },
)

const updateConfig = (type: string, config: ItemConfig, index?: number) => {
  switch (type) {
    case 'dataQualityReport': {
      importConfigRef.value = {
        ...importConfigRef.value,
        ...config,
      }
      break
    }

    case 'rules':
      if (importConfigRef.value?.rules && index !== undefined) {
        importConfigRef.value.rules[index].rule = {
          ...importConfigRef.value.rules[index].rule,
          ...config,
        }
      }
      break
  }
}

const onChangeValidation = async ({ name, index, type }: { name: string; index?: number; type: string }) => {
  switch (type) {
    case 'dataQualityReport': {
      const res = await validateDataQualityReportName(name)

      updateConfig(type, {
        existId: res.existId,
        isValid: res.isValid,
        name,
      })
      return res
    }

    case 'rules': {
      const res = await validateRuleName(name)

      updateConfig(
        type,
        {
          existId: res.existId,
          isValid: res.isValid,
          name,
        },
        // pass index to find the specific rule
        index,
      )
      return res
    }
  }
}

const createCopy = () => {
  if (!importConfigRef.value) return

  if (!importConfigRef.value.isValid) {
    importConfigRef.value.name = getUniqueNameDqr(importConfigRef.value.name)
  }

  importConfigRef.value.rules?.forEach(rule => {
    if (!rule.rule.isValid) {
      rule.rule.name = getUniqueNameRule(rule.rule.name)
    }
  })
}

const onActionTrigger = (type: ImportType) => {
  importTypeRef.value = type

  switch (type) {
    case ImportType.CREATE_COPY: {
      createCopy()
      break
    }

    case ImportType.OVERWRITE:
    case ImportType.DEFAULT: {
      importApi()
      break
    }
  }
}

const resetRef = () => {
  importConfigRef.value = undefined
  importTypeRef.value = undefined
}

const closePopup = (status: string = '', dqrId?: any) => {
  emit('close', status, dqrId)
}

// #region api
const getUniqueNameRule = (name: string = '') => {
  return generateUniqueName(
    `${name} (copy)`,
    props.dataQualityRules.map(rule => rule.name),
  )
}

const getUniqueNameDqr = (name: string = '') => {
  return generateUniqueName(
    `${name} (copy)`,
    props.dataQualityReports.map(rule => rule.name),
  )
}

const importApi = async () => {
  closePopup('loading')

  const res = await dataQualityReportService.import({
    ...importConfigRef.value,
    importType: importTypeRef.value,
  })

  if (res.error) {
    $root.toast({ description: res.error, type: 'error', timeout: 5000 })
    closePopup('error')
  } else {
    $root.toast({
      description: `${$root.t['dqc_import_success']}: ${importConfigRef.value?.name}`,
      type: 'success',
      timeout: 5000,
    })
    closePopup('success', res.data.id)
  }

  resetRef()
}

// Validate the Data Quality Report name
const validateDataQualityReportName = async (name: string): Promise<ValidationResponse> => {
  const res = validateNameEmpty(name)
  if (!res.isValid) return res

  const { id = null } = (await getByNameDQR(name)) || {}
  let isValid = !id
  let errMsg = id ? $root.t['validation_name_already_exists'] : ''

  if (importTypeRef.value === ImportType.OVERWRITE) {
    isValid = true
    errMsg = ''
  }

  return {
    isValid,
    errMsg,
    existId: id,
  }
}

const validateRuleName = async (name: string): Promise<ValidationResponse> => {
  const res = validateNameEmpty(name)
  if (!res.isValid) return res

  const { id = null } = await getByNameRule(name)
  let isValid = !id
  let errMsg = id ? $root.t['validation_name_already_exists'] : ''

  if (importTypeRef.value === ImportType.OVERWRITE) {
    isValid = true
    errMsg = ''
  }

  return {
    isValid,
    errMsg,
    existId: id,
  }
}

const validateNameEmpty = (name: string): ValidationResponse => {
  if (!name)
    return {
      isValid: false,
      errMsg: $root.t['validation_required'],
    }

  return {
    isValid: true,
    errMsg: '',
  }
}

const getByNameDQR = (name: string): Promise<{ id: number | string | null }> => {
  const item = props.dataQualityReports.find(item => {
    return item.name === name
  })

  return Promise.resolve({ id: item?.id || null })
}

const getByNameRule = (name: string): Promise<{ id: number | string | null }> => {
  const item = props.dataQualityRules.find(item => {
    return item.name === name
  })

  return Promise.resolve({ id: item?.id || null })
}

const isNoDuplication = computed(() => {
  const result = importConfigRef.value?.isValid && importConfigRef.value.rules?.every(rule => rule.rule.isValid)

  return result
})
//#endregion
</script>

<style scoped>
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.gap-1 {
  gap: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pt-2 {
  padding-top: 8px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.actions {
  display: flex;
}
</style>
