<template>
  <div class="flex-column flex" style="gap: 2px">
    <label>{{ title }}</label>
    <input
      type="text"
      class=""
      style="width: 100%"
      :value="nameRef"
      @input="event => onValueChang(event)"
      :placeholder="placeholder"
    />
    <div v-if="errMsgRef" class="error">
      {{ errMsgRef }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { debounce } from 'lodash-es'
import { ValidationResponse } from '../data-quality-report-import-popup.vue'

type Props = {
  value: string
  title: string
  placeholder: string
  onChangeValidation: (name: string) => Promise<ValidationResponse>
}

const props = defineProps<Props>()

const nameRef = ref(props.value)
const errMsgRef = ref('')

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (oldValue === newValue) return
    nameRef.value = newValue
    validate()
  },
)

const validate = async () => {
  try {
    const result = await props.onChangeValidation(nameRef.value)
    errMsgRef.value = result.errMsg
    return result.isValid
  } catch (error) {
    errMsgRef.value = $root.t['invalid_input_value']
    return false
  }
}

const onValueChang = debounce(async (event: any) => {
  nameRef.value = event.target?.value || ''
  await validate()
}, 300)

onMounted(validate)
</script>

<style scoped>
.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.error {
  color: grey;
  font-size: 12px;
}

.pb-1 {
  padding-bottom: 8px;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
</style>
